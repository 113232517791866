@use '@material/typography/mdc-typography';
@use '@material/layout-grid/mdc-layout-grid';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/select';
@use '@material/select/styles' as select-styles;
@use '@material/button/styles' as button-styles;
@use '@material/button';

body {
  font-family: sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.main-content {
  padding: 16px;
}

.hidden {
  display: none;
}

#login-button {
  @include button.container-fill-color(#a1c349);
  @include button.ink-color(#000);

  width: 100%;
  margin-top: 2%;
  margin-bottom: 8%;
  padding: 3em;
  font-size: large;
  font-weight: bold;

  a {
    text-decoration: none;
    color: white;
  }
}

.load-more-button {
  @include button.container-fill-color(#99e2f5);
  @include button.ink-color(rgb(63, 63, 63));

  width: 100%;
  margin-top: 2%;
  font-weight: bold;
}

#time-range-select {
  margin-top: 1em;
  width: 100%;

  ul {
    padding-bottom: 0;

    li {
      padding: 1em 1em;
    }
  }
}

.cell {
  box-sizing: border-box;
  background-color: #f3f3f3;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 0.5em;

  img {
    border-radius: 5px;
    // width: 300px;
    // object-fit: none;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .spotify-link {
    text-decoration: none;
    color: #000;
  }

  .info {
    margin-top: 0.25em;

    h5 {
      @extend .mdc-typography--headline5;
      margin-top: 0;
      margin-bottom: 0.1em;
      text-decoration: underline;
    }

    h6 {
      @extend .mdc-typography--subtitle2;
      margin: 0;
      opacity: 0.6;
    }
  }
}

.error-message {
  @extend .mdc-typography--subtitle1;
  opacity: 0.6;
}

footer {
  width: 100%;
  padding: 1em 0;
  margin-top: auto;
  text-align: center;
  font-size: 1em;
  background-color: rgb(110, 110, 110);
  color: white;

  .material-icons {
    font-size: 1em;
    margin-right: 0.2em;
  }

  .footer-links {
    margin-top: 0.2em;

    a {
      color: white;
      text-decoration: underline;

      :visited {
        color: white;
      }

      margin: 0 0.2em;
    }
  }
}
